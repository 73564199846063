* {
  /* margin: 0;
  padding: 0; */
  font-family: "PT Sans", proxima-nova, sans-serif;
}

/* The blue is #004A8E, 
grey is #F9F9F9, 
green is #03AE7A, 
red #EE4646 \ */

button {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

#container-div {
  width: 100%;
}

#app-div {
  margin: auto;
  width: 500px;
}

#greenClass {
  background-color: green;
  color: green;
}

#redClass {
  background-color: red;
  color: red;
}

/* Custom CSS Sustain button 
*/

/*.sustainButton {*/
.issue-card-buttons {
  width: 60%;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
  background-color: rgb(3, 174, 122);
  border: none;
  border-radius: 6px;
  padding: 0.65rem 2.4rem;
  margin: 0 auto;
}

.greenClass {
  color: green;
}

.officer-green {
  color: green;
}

.redClass {
  color: red;
}

.index-members-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.index-sustain-ten-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.sustain-ten-hover-div:hover {
  background-color: #d8f0f3;
  border-radius: 10px;
}

.footer-list {
  margin: 0;
  padding: 0;
}

.footer-list-item {
  display: inline-block;
  padding: 5px;
  margin: 4px;
  border-radius: 7px;
  width: 22%;
  text-align: center;
  justify-content: center;
  font-size: 0.75em;
}

.footer-div {
  display: inline-block;
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -0%);
  width: 100%;
  max-width: 500px;
  text-align: center;
  background: white;
  padding: 5px;
  z-index: 100;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.4);
}

.header-div {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  width: 100%;
  text-align: center;
}

.header-nav-div {
  width: 100%;
  background-color: #004a8e;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 75;
  /* border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; */
}

.header-icon {
  width: 150px;
}
.border-none {
  border: none;
}

.index-button-text {
  margin-left: -6%;
}

.index-header-title {
  margin: 0 auto;
  text-align: center;
  width: 90%;
}

#dropdown-nav-menu {
  margin: 10px 0 10px 5%;
  z-index: 100;
}

:root {
  --black: #000000;
  --blue: #0d10e9;
  --gray: #808080;
  --lightBlue: #4dd2ff;
  --lightGray: #d1d4db;
  --orange: #ffa500;
  --white: #ffffff;
}

.hidden {
  display: none;
}

#issueCard-button-read {
  margin: 0px auto;
  border-radius: 4px;
  padding: 5px 5px 10x 5px;
  font-size: 18px;
}

.twitter-share-div {
  width: 100%;
  display: flex;
}

.twitter-share-button {
  text-align: right;
  flex: 1;
  color: white;
  margin-top: -25px;
  margin-right: 5px;
  width: 25px;
}

.twitter-share-button:hover {
  cursor: pointer;
}

.engage-user-engage {
  margin-bottom: 30px;
  padding-bottom: 10px;
  margin-top: 25px;
  font-size: 1.2em;
}

.engage-user-card-text {
  margin: 0 0 0 15px;
}

.user-level {
  color: #059900;
}

.engage-title {
  margin-bottom: 10px;
  text-align: center;
}

.engage-container {
  width: 100%;
}

.engage-list {
  list-style: none;
  border: 1px solid var(--black);
  border-radius: 5px;
  padding: 15px;
  margin: 0 10px 20px;
}

.engage-ul {
  width: 90%;
  margin: 0 auto 100px;
}
.engage-ul-type2 {
  width: 90%;
  margin: 0 auto 100px;
}

.engage-list-image {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.engage-text-area {
  /* flex: 1; */
  width: 100%;
}

.engage-card-parent-li {
  display: flex;
}

.comment-submit-buttons {
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
}

.profile-vote-li {
  flex: 1;
}

.issue-card-button-over-image {
  position: absolute;
  border-radius: 20px;
  background-color: gray;
  opacity: 0.8;
  margin-top: 10px;
  margin-left: 3%;
  padding: 7px;
  font-size: 1em;
  width: 94%;
  text-align: left;
  z-index: 50;
}

.issue-card-image-div {
  position: relative;
  width: 100%;
  height: 250px;
  margin: 0 auto;
}

.issue-card-image {
  /* position: absolute; */
  height: 100%;
  width: 100%;
  border-radius: 3px;
  /* -webkit-transform: translate(-50%, -50%); */
  /* -ms-transform: "translate(-50%,-50%)";*/
  transform: "translate(-50%,-50%)";
}

.issue-card-button-retweet-icon {
  float: right;
  color: white !important;
  opacity: 1 !important;
  font-size: 25px;
  z-index: 50;
  position: relative;
  display: inline-block;
  margin: 0 auto;
  padding-top: 5px;
  padding-right: 5px;
}

.issue-card-button-share-icon {
  float: right;
  color: white !important;
  z-index: 50;
  position: relative;
  margin: 0 auto;
  bottom: 10px !important;
  padding-right: 14px;
}

.issue-card-button-over-image-ul {
  list-style: none;
  padding-left: 2px;
}

.issue-card-button-over-image-li {
  display: inline-block;
  margin-left: 2px;
  color: white;
  letter-spacing: 2px;
  font-weight: 600;
}

#stock-change {
  padding: 2px 3px;
  background-color: green;
}

.issue-card-button-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

/*.issue-card-buttons {
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  width: 80%;
  margin: 0 auto;
}*/

.engage-card-buttons-div {
  display: flex;
}

.engage-card-buttons {
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  flex: 1;
  margin: 0 5px;
}

.modal-card {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2vh;
  width: 90%;
  max-height: 75vh;
}

.modal-card-input {
  width: 30%;
  margin: 0 auto;
}

.register-spacing-div {
  min-height: 60px;
}
/* disable hover on mobile */
@media (max-width: 420px) {
  #app-div {
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
  }

  .header-nav-div {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .index-members-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .engage-ul-type2 {
    width: 97%;
    margin: 0 auto 100px;
  }
  .footer-list-item:hover {
    background: none;
    font-size: 15px;
  }

  .issue-card-image-div {
    position: relative;
    width: 100%;
    height: 220px;
    margin: 0 auto;
  }

  .issue-card-buttons {
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 8px;
    margin: 0 auto;
  }
  .modal-card {
    max-height: 75vh;
  }

  .modal-card-input {
    width: 80%;
  }
}

.issue-card-button-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
}

.sustain-blue {
  color: #004a8e;
}

/* Iphone 11 */
@media (max-width: 375px) {
  #app-div {
    margin-left: 10px;
    margin-right: 10px;
    width: auto;
  }

  .header-nav-div {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .index-members-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .footer-list-item:hover {
    background: none;
    font-size: 15px;
  }

  .issue-card-image-div {
    position: relative;
    width: 100%;
    height: 200px;
    margin: 0;
  }

  .issue-card-button-div {
    grid-gap: 15px;
  }

  .issue-card-buttons {
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 8px;
    min-width: 25px;
    text-align: center;
  }
  .modal-card-input {
    width: 80%;
  }
}
/* take view height and subtract height of header and footer
min-height: calc(100vh - 10px);
*/
