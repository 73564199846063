/* .user-bio {
  text-align: left;
} */

.user-vote {
  font-size: 1.25em;
}

.comment-discuss {
  margin: 10px;
}

.share-div {
  width: 80%;
  margin: 20px auto 10px;
  border: 1px gray;
  border-radius: 10px;
  box-shadow: 3px 3px 3px 3px gray;
  padding: 10px;
  color: #004a8e;
}

.description-text-area {
  margin-left: 10%;
}

.profile-vote-li {
  border: 1px solid gray;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.user-profile-header {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.sustain-statement {
  margin: 0 auto;
  width: 100%;
  text-align: left;
}

.user-name {
  justify-content: center;
  align-items: center;
  font-size: 2em;
  padding: 15px;
  text-align: left;
}

.location-name {
  justify-content: center;
  align-items: center;
  font-size: 1em;
  padding: 15px;
  text-align: left;
}
.image-and-edit {
  min-height: 150px;
}

.profile-image-container {
  height: 100px;
  width: 100px;
  margin: 0 auto;
}
.profile-image {
  border-radius: 50%;
  height: 100%;
}

.Inline {
  display: inline-block;
}
.profile-edit-button {
  background-color: #004a8e;
  color: white;
  padding: 10px 10px;
  margin: 0px auto;
  width: 20%;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 6px;
  font-weight: 500;
  font-size: 15px;
}

.profile-update-button {
  background-color: #004a8e;
  color: white;
  padding: 10px 10px;
  margin: 0px auto;
  width: 20%;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 6px;
  font-weight: 500;
  font-size: 15px;
}

.display-name-vote-unsustain {
  color: #ee4646;
}

.display-name-vote-sustain {
  color: #03ae7a;
}
