.engage-list-type2 {
  list-style: none;
  /* border: 1px solid var(--black); */
  border-top: 1px dotted lightgray;
  border-radius: 5px;
  line-height: 20px;
  padding: 0px;
  margin: 0 0px;
}

.engage-list-image-type2 {
  width: 20px;
  height: 20px;
  border-radius: 50px;
}

.engage-user-engage-type2 {
  margin-bottom: 30px;
  padding-bottom: 10px;
  margin-top: 25px;
  font-size: 1.2em;
}
.engage-user-card-bio-2 {
  margin-left: 15px;
}

.engage-user-card-text-type2 {
  display: grid;
  grid-template-columns: 325px 1fr;
  margin: 0 0 0 15px;
}
.engage-user-card-text-type2:hover {
  background-color: lightsteelblue;
  border-radius: 5px;
  padding: 5px;
}
.engage-user-content-container {
  padding: 0px;
  margin: 0px;
  align-items: center;
}
engage-div-doNothing {
  padding: 0;
  margin: 0;
}
.engage-user-name-button {
  background: none;
  border: none;
  cursor: pointer;
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0px;
  padding: 0;
  text-align: left;
}
.render-total-type2 {
  display: inline-block;
  padding: 5px;
  padding-top: 10px;
  line-height: 1;
}
.user-level-type2 {
  color: #059900;
  font-weight: "bold";
  letter-spacing: 0.5px;
}
.user-photo-plus-name {
  line-height: 1;
  padding: 5px;
}
.user-type-2-engage-link {
  /* background-color: #bdffea;
  border: 1px solid lightblue; */
  border-radius: 4px;
  /* color: black; */
  line-height: 1.5;
  /* margin-bottom: 10px; */
  margin-left: 25px;
  padding: 4px;
}

.user-type-2-engage-link:hover {
  /* background-color: lightblue;*/
}

@media (max-width: 420px) {
  .engage-list-type2 {
    padding: 3px;
    margin: 0 5px;
  }
  .engage-user-card-text-type2 {
    display: grid;
    grid-template-columns: 240px 1fr;
    margin: 0 0 0 15px;
  }
  .user-level-type2 {
    font-size: 0.75em;
  }
}
