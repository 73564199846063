/* .index-subtitle {
  text-align: center;
} */
.expand-body-p {
  font-style: italic;
}
.expanded-footer {
  padding: 10px;
}
.next-fund-sign-up-btn {
  /* width: 60%; */
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  cursor: pointer;
  display: block;
  font-family: "PT Sans", proxima-nova, sans-serif;
  font-size: 1rem;
  margin: 0 auto;
  padding: 10px;
  /* margin: 0 auto; */
}

.new-fund-header {
  display: inline-flex;
  justify-content: center;
  width: 100%;
}

.new-fund-header img {
  height: 50px;
  margin-right: 10px;
}

.new-fund-header-title {
  border: none;
  background: none;
  color: black;
  cursor: pointer;
  display: block;
  font-size: 2rem;
  text-decoration: none;
}

.new-fund-header-title:focus {
  outline: none;
}

@media (max-width: 450px) {
  .new-fund-header-title {
    font-size: 1rem;
  }

  .next-fund-sign-up-btn {
    /* width: 60%; */
    background-color: white;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.75rem;
    padding: 10px;
    /* margin: 0 auto; */
  }
}
